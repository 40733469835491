.Portfolio {
  display: flex;
  flex-wrap: wrap;
  margin: 80px auto 40px;
}

.Portfolio h2 {
  width: 100%;
  font-size: 25px;
  margin: 12px 36px;
}

.Portfolio h3 {
  padding: 8px 36px;
  width: 100%;
  font-weight: 400;
}

.Portfolio .filters {
  margin: 0 auto 40px;
  text-align: center;
}

.Portfolio .filters button {
  outline: none;
}

.Portfolio .filters .filter {
  font-weight: 600;
  border: 1px solid #fff;
  background: #0a1c15;
  padding: 12px 20px;
  line-height: 1;
  border-radius: 5px;
  margin: 12px 4px 0;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (min-width: 473px) {
  .Portfolio .filters {
    width: 90%;
  }
}

@media (min-width: 521px) {
  .Portfolio .filters {
    width: 79%;
  }
}

@media (min-width: 706px) {
  .Portfolio .filters {
    width: 100%;
  }
}

@media (min-width: 780px) {
  .Portfolio .filters .filter {
    margin: 12px 6px 0;
  }
}

@media (min-width: 880px) {
  .Portfolio .filters .filter {
    margin: 12px 12px 0;
  }
}

.Portfolio .filters .filter:hover {
  cursor: pointer;
  color: #fff;
  background-color: #721e24;
  border: 1px solid #721e24;
}

.Portfolio .filters .filter.selected {
  color: #fff;
  background-color: #721e24;
  border: 1px solid #721e24;
}

.Portfolio .filters .filter.selected:hover {
  color: #fff;
}

