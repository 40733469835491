.Intro {
  margin-top: 40px;
}

.Intro .heading {
  text-align: center;
  padding-right: 15%;
  padding-left: 15%;
  margin-bottom: 200px;
}

.Intro .heading h1 {
  margin-bottom: 27px;
  color: #fff;
  font-size: 28px;
  line-height: 53px;
  font-weight: 300;
}

.Intro .heading .button {
  border: 1px solid #0a1c15;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #0a1c15;
  font-size: 13px;
  line-height: 19px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 9px 25px;
  text-decoration: none;
  cursor: pointer;
}

.Intro .heading .button:hover {
  color: #fff;
  background-color: #721e24;
  border: 1px solid #721e24;
}

.Intro h3 {
  margin-top: 0;
  margin-bottom: 32px;
  color: #fff;
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 3px;
  text-transform: none;
  line-height: 30px;
  text-transform: uppercase;
}

.Intro .section-title {
  font-weight: 700;
}

.Intro .more {
  text-align: center;
}

.Intro .more .button {
  border: 1px solid #fff;
  border-radius: 3px;
  background-color: initial;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  font-family: roboto_condensedregular, sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 19px;
  font-weight: 700;
  display: inline-block;
  padding: 9px 25px;
  text-decoration: none;
  cursor: pointer;
}

.Intro .more .button:hover {
  color: #fff;
  background-color: #721e24;
  border: 1px solid #721e24;
}

.Intro .more .button:focus {
  outline: #721e24;
}

@media (min-width: 480px) {
  .Intro .heading h1 {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .Intro .heading h1 {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  .Intro .heading h1 {
    font-size: 48px;
  }
}

@media (min-width: 566px) and (max-width: 767px) {
  .Intro .Portfolio .Card:nth-child(3) {
    display: none;
  }
}

.fade-in {
  transition: opacity 1.5s ease;
  animation: rotationIn 1s linear;
}

.fade-out {
  opacity: 0;
  transition: opacity 1.5s ease;
  animation: rotationOut 1s linear;
}