.Contact {
  margin-top: 60px;
  text-align: center;
}

@media (min-width: 880px) {
  .Contact {
    margin: 20px 100px;
  }
}

.Contact .heading {
  padding-right: 15%;
  padding-left: 15%;
  margin-top: 40px;
  margin-bottom: 16px;
}

.Contact .heading h1 {
  color: #fff;
  font-size: 3.6em;
  line-height: 53px;
  font-weight: 700;
}

.Contact .form {
  display: block;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
  padding: 40px;
}

.Contact .form form {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.Contact .field {
  font-family: roboto_condensedregular, sans-serif;
  font-size: 12px;
  font-weight: 300;
  float: left;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
}

.Contact .input {
  min-height: 50px;
  margin-bottom: 19px;
  border: 1px solid #0a1c15;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  color: #0a1c15;
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: 700;
}

.Contact .input:focus {
  outline: none !important;
  border: 1px solid #20b376;
}

.Contact .input:focus:nth-child(3n + 2) {
  outline: none !important;
  border: 1px solid #4676fd;
}

.Contact .input:focus:nth-child(3n + 3) {
  outline: none !important;
  border: 1px solid #f78f40;
}

.Contact .button {
  border: 1px solid #fff;
  border-radius: 3px;
  background-color: initial;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  font-family: roboto_condensedregular, sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 19px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 9px 25px;
  text-decoration: none;
  cursor: pointer;
}

.Contact .button:hover {
  color: #fff;
  background-color: #721e24;
  border: 1px solid #721e24;
}

.Contact .button:focus {
  outline: #721e24;
}

.Contact .input.text-area {
  height: 150px;
}

@media (min-width: 600px) {
  .Contact .heading h1 {
    font-weight: 500;
  }
}

.Typist .Cursor {
  display: inline-block;
  color: #fff !important;
}
