body {
  margin: 0;
  padding: 0;
  font-family: tomorrow_mediumregular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
  font-size: .86em;
}
