.Footer {
  margin: 0 auto;
  text-align: center;
  padding: 80px 0 40px;
}

.Footer a {
  margin: 40px;
}

.Footer a:hover {
  color: #8466cb;
}

.Footer a:hover:nth-child(3n + 2) {
  color: #0076b5;
}

.Footer a:hover:nth-child(3n + 3) {
  color: #00acee;
}
