.Overlay {
  padding: 40px 8%;
}

.Overlay button {
  background: 0 0;
  border: none;
  color: #fff;
  cursor: pointer;
}

.Overlay a {
  text-decoration: none;
  color: #fff;
  /* -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear; */
  font-weight: 700;
}

.Overlay .more {
  font-family: roboto_condensedregular, sans-serif;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  background-color: initial;
  cursor: pointer;
  text-align: center;
  display: block;
  font-size: 20px;
  margin: 60px auto;
}

.Overlay .more a:hover {
  -webkit-text-decoration: underline #721e24 3px;
  text-decoration: underline #721e24 3px;
}

.Overlay .more .backArrow {
  margin-right: 12px;
}

.Overlay .more:hover .backArrow {
  color: #721e24;
}



.OverlayItem h1 {
  margin-bottom: 2px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 2px 10px;
}

.OverlayItem .position {
  font-size: 1.3em;
  margin-bottom: 4px;
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .OverlayItem .position {
    display: block;
  }
}

.OverlayItem .description {
  margin-top: 28px;
  text-align: justify;
  text-indent: 30px;
  font-size: 1.4em;
  line-height: 1.6em;
}

.OverlayItem .icon {
  margin-top: 18px;
  text-align: center;
  font-size: 4em;
  line-height: 1.4em;
}

.Overlay h1,
.OverlayItem p b {
  font-weight: 900;
}

.Overlay h1 {
  margin-bottom: 8px;
  font-size: 34px;
}

@media (min-width: 992px) {
  .Overlay h1 {
    font-size: 40px;
  }
}
