.Card {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 268px;
  height: 375px;
  margin: 0 auto 30px;
  box-shadow: 0px 0px 0px 3px rgb(0 0 0 / 15%);
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Card:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

@media (min-width: 566px) {
  .Card {
    width: 42%;
  }
}

@media (min-width: 768px) {
  .Card {
    width: 30%;
  }
}

.Card .wrapper {
  width: 100%;
  text-align: center;
  background-color: #f8f8ff;
  font-size: 3em;
}

.Card .innerContent {
  display: inline-block;
  margin: 150px auto;
  padding: 3px;
}

.Card .wrapper img {
  max-width: 100px;
  max-height: 100px;
  align-self: center;
}

.Card .wrapper.hasIcon {
  padding: 106px 40px 40px;
}

.Card .wrapper .title {
  font-family: gothic_jokerregular, sans-serif;
  font-size: 2em;
  text-shadow: rgba(0, 0, 0, 0.01) 0 2px 10px;
  color: #0a1c15;
}

.Card .wrapper .topLeft {
  position: absolute;
  color: #0a1c15;
  top: 8px;
  left: 16px;
  font-size: 0.64em;
}

.Card .wrapper .topLeftBottom {
  position: absolute;
  color: #721e24;
  top: 42px;
  left: 12px;
  font-size: 0.64em;
}

.Card .wrapper .topRight {
  position: absolute;
  top: 8px;
  right: 8px;
}

.Card .wrapper .topRight .badge {
  background-color: #0a1c15;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  color: #fff;
  font-size: 12px;
  top: 0;
  right: 0;
}

.Card .wrapper .bottomRight {
  position: absolute;
  color: #0a1c15;
  bottom: 8px;
  right: 16px;
  font-size: 0.64em;
}

.Card .wrapper .bottomRightTop {
  position: absolute;
  color: #721e24;
  bottom: 42px;
  right: 12px;
  font-size: 0.64em;
}

.Card .textWrapper {
  display: block;
  padding: 40px 0 0;
  justify-content: center;
  align-items: center;
}

.Card .textWrapper.white {
  color: #000;
}

.Card .textWrapper h4 {
  padding: 0;
  font-size: 1.5rem;
  letter-spacing: 0.01em;
  text-shadow: rgba(0, 0, 0, 0.01) 0 2px 10px;
}

.Card .textWrapper p {
  line-height: 20px;
  font-size: 1rem;
  /* opacity: 0.75; */
  padding: 0;
  /* text-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px; */
  color: #0a1c15;
}

.Card .label {
  font-family: tomorrow_mediumregular, sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  position: absolute;
  right: 17px;
  top: 15px;
  background-color: #fff;
  font-size: 12px;
  border-radius: 2px;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  padding: 2px 8px 1px;
  margin: 0;
  z-index: 2;
}

/* Udemy Card */
.Card:hover .Udemy {
  color: #6c44bc;
}

/* IBM Card */
.Card:hover .IBM {
  color: #006699;
}

/* Zello Card */
.Card:hover .Zello {
  color: #ff350d;
}

/* Key Card */
.Card:hover .Key {
  color: rgb(63, 181, 98);
}

/* Quant Card */
.Card:hover .Quant {
  color: rgb(55, 48, 121);
}


