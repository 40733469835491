.NotFound {
  margin: 20px 20px;
  text-align: center;
}

.NotFound .heading {
  margin-bottom: 100px;
}

.NotFound .heading h1 {
  color: #fff;
  font-size: 2em;
  line-height: 53px;
  font-weight: 500;
}

.NotFound p,
.NotFound span {
  text-align: center;
  text-indent: 30px;
  font-size: 1.4em;
  line-height: 1.3em;
  line-height: 1.6em;
}

.interstitial-wrapper {
  margin: 20px auto 0;
}
