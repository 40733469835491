@font-face {
  font-family: "gothic_jokerregular";
  font-style: normal;
  font-weight: 400;
  src: local("Gothic-Joker"), url(./../../media/Gothic-Joker.ttf);
}

@font-face {
  font-family: "roboto_condensedregular";
  font-style: normal;
  font-weight: 400;
  src: local("RobotoCondensed-Regular"),
    url(./../../media/RobotoCondensed-Regular.ttf);
}

body,
html {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: roboto_condensedregular, sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  background: url(./../../images/portfolio/smoke-lg.png);
}

a {
  color: #fff;
}

a,
a:hover {
  text-decoration: none;
}

a:hover {
  color: #fff;
}

button,
button:focus {
  outline: none;
}

.signature {
  font-family: gothic_jokerregular, Helvetica;
  font-size: 4em;
  transition: color 0.5s ease;
  -webkit-transition: color 0.5s ease;
}

.Typist {
  font-weight: 500;
  font-family: gothic_jokerregular, Helvetica;
}

.Typist .Cursor {
  display: inline-block;
  color: #0a1c15;
}

.Typist .Cursor--blinking {
  opacity: 1;
  -webkit-animation: blink 1s linear infinite;
  animation: blink 1s linear infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
