.About {
  margin: 100px 20px 20px;
  text-align: center;
}

.About p {
  text-align: justify;
  text-indent: 30px;
  font-size: 1.4em;
  line-height: 1.3em;
  line-height: 1.6em;
}

.About a {
  text-decoration: none;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  font-weight: 700;
}

.About a:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

@media (min-width: 600px) {
  .About .heading h1 {
    font-weight: 500;
  }
}
